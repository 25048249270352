import React, {Component} from 'react';
import {Button, Col, Grid, Image, Jumbotron, Row} from "react-bootstrap";

class Testimony extends Component {
    render() {
        let image_url = this.props.image
        let text = this.props.text
        let name = this.props.name
        return (
            <Grid>
                <Row>
                    <Col md={4} mdOffset={4}>
                        <Image src={image_url} responsive/>
                    </Col>
                </Row>
                <Row>
                    <Col md={8} mdOffset={2}>
                        <Jumbotron style={{backgroundColor: "white"}}>
                            <h2>{name}</h2>
                            {text}

                            <Button
                                href="https://square.site/book/EXXGZVK2WV0SG/mardi-mcdonald-liv-salons-salt-lake-city-ut"
                                size="lg" block>
                                Book Online
                            </Button>

                        </Jumbotron>
                    </Col>
                </Row>
            </Grid>
        );
    }
}

export default Testimony;