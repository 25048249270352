import React, {Component} from 'react';
import {
    HashRouter,
    Route,
} from 'react-router-dom';

import Home from './Home.js';
import Testimonials from './Testimonials/Testimonials';


class App extends Component {
    render() {
        return (

            <Home/>

        );
    }
}

export class Routes extends Component {
    render() {
        return (
            <HashRouter>
                    <Route exact name='app' path='/' component={App}/>
                    <Route name='test' path='/testimonials' component={Testimonials}/>
            </HashRouter>
        )
    }
}


