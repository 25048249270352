import React, {Component} from 'react';
import {Grid, Row, Col, Image, Jumbotron, Thumbnail, Button} from 'react-bootstrap';
import NavHeader from '../NavHeader';
import Testimony from "./Testimony";
import {getTestimonies} from "./testimonies";

class Testimonials extends Component {
    render() {
        let rows = []

        for (let testimony of getTestimonies()){
            rows.push(
                <Row>
                    <Testimony image={testimony['url']} text={testimony['text']} name={testimony['name']}/>
                </Row>
            )
        }

        return (
            <Grid style={{marginBottom: "20px"}}>
                <Row>
                    <NavHeader/>
                </Row>
                <Row>
                    <Col md={8} mdOffset={4} style={{marginBottom: "20px"}}>
                        <h1>TESTIMONIALS</h1>
                    </Col>
                </Row>
                {rows}
            </Grid>
        );
    }
}

export default Testimonials;
