import React, {Component} from 'react';
import {Grid, Nav, Navbar, NavItem, Row} from "react-bootstrap";


class NavHeader extends Component {
    render() {
        return (
                <Navbar inverse>
                    <Navbar.Header>
                        <Navbar.Brand>
                            <a href="#">Mardi McDee Hairstylist</a>
                        </Navbar.Brand>
                    </Navbar.Header>
                    <Nav pullRight>
                        <NavItem eventKey={1}
                                 href="#testimonials">
                            Testimonials
                        </NavItem>
                        <NavItem eventKey={1}
                                 href="https://form.jotform.com/91146008403144">
                            New Client Form
                        </NavItem>
                        <NavItem eventKey={1}
                                 href="https://form.jotform.com/201097892649064">
                            Extension Client Form
                        </NavItem>
                        <NavItem eventKey={1}
                                 href="https://square.site/book/EXXGZVK2WV0SG/mardi-mcdonald-liv-salons-salt-lake-city-ut">
                            Book Online
                        </NavItem>
                        <NavItem eventKey={2} target="_blank"
                                 href="https://www.instagram.com/mardimcdeehairstylist/">
                            Instagram
                        </NavItem>
                        {/*<NavItem eventKey={3} target="_blank" href="https://www.facebook.com/mardimcdeehairstylist">*/}
                        {/*    facebook*/}
                        {/*</NavItem>*/}
                    </Nav>
                </Navbar>
        );
    }
}

export default NavHeader;