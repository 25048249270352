import React from 'react';

export function getTestimonies() {
    let testimonies = []

    testimonies.push({
        'url': "/testimonials/ashlyn.jpg",
        'name': "Ashlyn H",
        'text': (
            <div>
                <p>
                    "I love getting my hair done by Mardi! She does a fantastic job! She knows what she's doing.
                    The cut and color always turn out perfect!! She also gives me a diet coke every time I'm there.
                    Which is the cherry on top of a great Appointment!"
                </p>
            </div>
        )
    })

    testimonies.push({
        'url': "/testimonials/rilee.JPG",
        'name': "Rilee N",
        'text': (
            <div>
                <p>
                    “I look forward to my hair appointment with Mardi every time! She always listens to what I want to
                    do even if sometimes I don't even know. From going blonde to pink to almost black I’ve been obsessed
                    with every cut and color she’s done on me! Every recommendation always turns out beautiful! Plus the
                    aura that surrounds her and her salon is a comforting, kind, safe place to talk about whatever and I
                    really appreciate that about her. Been coming to her for 10 years and I can’t see myself going
                    anywhere else!"
                </p>
            </div>
        )
    })

    testimonies.push({
        'url': "/testimonials/jalessa.JPG",
        'name': "Jalessa V",
        'text': (
            <div>
                <p>
                    "Want to feel like a Goddess? Get your hair done by Mardi. Mardi really cares about her clients &
                    the integrity of their hair. She'll help your hair dreams come true. while keeping your hair
                    healthy! She's easy to talk to & is easy to relate to! She also can answer any questions related to
                    products to help keep that fresh salon hair longer!"
                </p>
            </div>
        )
    })

    testimonies.push({
        'url': "/testimonials/des.jpg",
        'name': "Desiree E",
        'text': (
            <div>
                <p>
                    "Never Disappointed! I always leave the salon feeling amazing and with bombshell hair! Highly
                    recommend Mardi!"
                </p>
            </div>
        )
    })


    testimonies.push({
        'url': "/testimonials/brit.JPG",
        'name': "Britney L",
        'text': (
            <div>
                <p>
                    "Mardi is a hair Goddess! She gives you exactly what you ask for and is such a blast to be around!
                    She does great hair and makes a great friend!"
                </p>
            </div>
        )
    })

    return testimonies
}
