import React, {Component} from 'react';
import {Grid, Row, Col, Navbar, Nav, NavItem, Image, Jumbotron, Thumbnail, Button} from 'react-bootstrap';
import NavHeader from './NavHeader';

class App extends Component {
    render() {
        return (
            <Grid style={{marginBottom: "20px"}}>
                <Row>
                    <NavHeader/>
                </Row>
                <Row>
                    <Col md={6} mdOffset={3}>
                        <Image src="/mardi.JPG" responsive/>
                    </Col>
                </Row>
                <Row>
                    <Col md={8} mdOffset={2}>
                        <Jumbotron style={{backgroundColor: "white"}}>

                            <p>
                                Hi!
                            </p>
                            <p>
                                My name is Mardi McDonald. I began my hair journey in 2016 when I started as an
                                assistant at Liv Salons, a modern open-concept studio salon in Salt Lake City. After a
                                few years I moved on and started building my clientele. In just 6 months I had signed a
                                lease for a beautiful, spacious studio of my own! For me, working behind the
                                chair has evolved from a love of all things beauty, to an all consuming PASSION. I
                                quickly found my niche in Lived-in color and Beaded Row extensions. I love giving my
                                clients the longevity that comes with a lived-in look. And seeing the joy on someone's
                                face after giving them the hair they've always wanted with extensions, let's just say it
                                never gets old!
                            </p>
                            <p>
                                I take great pride in my craft and my industry, and im always looking for new ways to
                                take my client experience to the next level. When I graduated cosmetology school, I
                                couldn't wait to help women embrace their true inner and outer beauty. Now, it’s
                                much more than that. Working behind the chair has enriched my life in ways I couldn't
                                have imagined. My promise to all my clients is when you sit in my chair, your concerns
                                will be heard, your needs will be met to the best of my ability, and you will leave
                                feeling better than you did when you walked in.
                            </p>
                            <p>
                                -M
                            </p>

                            <Button
                                href="https://square.site/book/EXXGZVK2WV0SG/mardi-mcdonald-liv-salons-salt-lake-city-ut"
                                size="lg" block>
                                Book Online
                            </Button>

                        </Jumbotron>
                    </Col>
                </Row>
                <Row>
                    <Col md={6} mdOffset={3}>
                    </Col>
                </Row>
                <Row>
                    <Col xs={4} md={3} mdOffset={2}>
                        <Thumbnail target="_blank" href="https://www.instagram.com/mardimcdeehairstylist/" alt="171x180"
                                   src="/img1.jpg"/>
                    </Col>
                    <Col xs={4} md={3}>
                        <Thumbnail target="_blank" href="https://www.instagram.com/mardimcdeehairstylist/" alt="171x180"
                                   src="/img2.jpg"/>
                    </Col>
                    <Col xs={4} md={3}>
                        <Thumbnail target="_blank" href="https://www.instagram.com/mardimcdeehairstylist/" alt="171x180"
                                   src="/img3.jpg"/>
                    </Col>
                </Row>
                <Row>
                    <Col xs={4} md={3} mdOffset={2} style={{paddingTop: "102px"}}>
                        <Thumbnail target="_blank" href="https://www.instagram.com/mardimcdeehairstylist/" alt="171x180"
                                   src="/img4.jpg"/>
                    </Col>
                    <Col xs={4} md={3}>
                        <Thumbnail target="_blank" href="https://www.instagram.com/mardimcdeehairstylist/" alt="171x180"
                                   src="/img5.jpg"/>
                    </Col>
                    <Col xs={4} md={3} style={{paddingTop: "102px"}}>
                        <Thumbnail target="_blank" href="https://www.instagram.com/mardimcdeehairstylist/" alt="171x180"
                                   src="/img6.jpg"/>
                    </Col>
                </Row>
                <Row>
                    <Col xs={4} md={3} mdOffset={2}>
                        <Thumbnail target="_blank" href="https://www.instagram.com/mardimcdeehairstylist/" alt="171x180"
                                   src="/img7.jpg"/>
                    </Col>
                    <Col xs={4} md={3}>
                        <Thumbnail target="_blank" href="https://www.instagram.com/mardimcdeehairstylist/" alt="171x180"
                                   src="/img8.jpg"/>
                    </Col>
                    <Col xs={4} md={3}>
                        <Thumbnail target="_blank" href="https://www.instagram.com/mardimcdeehairstylist/" alt="171x180"
                                   src="/img9.jpg"/>
                    </Col>
                </Row>
                <Row>
                    <Col md={2} mdOffset={6} xsOffset={5} xs={2}>
                        <a target="_blank" href="https://www.instagram.com/mardimcdeehairstylist/">
                            <Image src="/instagram.png"/>
                        </a>
                    </Col>
                </Row>
            </Grid>
        );
    }
}

export default App;
